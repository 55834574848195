import {createApp} from 'vue'
// import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import { sync } from 'vuex-router-sync'
import store from './store'
import vuetify from './plugins/vuetify'
import components from './plugins/components'

import { loadFonts } from './plugins/webfontloader'

import VueRewards from "vue-rewards";
import Vue3Storage, { StorageType } from "vue3-storage"

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vueKanban from 'vue-kanban';

// import VueSession from 'vue-session'
// import IdleVue from 'idle-vue'

sync(store, router)

loadFonts()

let app = createApp(App)

// const storage = useStorage();
const baseURL = process.env.NODE_ENV === 'production' ? 'https://api-sia.ws-ssp.guanajuato.gob.mx/' : 'http://10.10.135.140:8000/'

app.config.globalProperties.$globalData = { menuLabel: '',idUsr: 0, usr: '', baseURL: baseURL}

// app.config.globalProperties.$storage =  useStorage()

app.use(router)
   .use(store)
   .use(vuetify)
   .use(VueRewards)
   .use(components)
   .use(VueSweetalert2)
   .use(vueKanban)
   // .use(VueSession)
   .use(Vue3Storage, { namespace: "pro_", storage: StorageType.Local})
   // .use(createPinia())
   .mount('#app')
